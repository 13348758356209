(function () {
    whileWorking.$inject = ['$parse', '$animate', 'watchablePromise'];
    function whileWorking($parse, $animate, watchablePromise) {
        return {
            restrict: 'A',
            link: function (scope, element, attributes) {
                var css = 'working';
                var current;
                var working = false;

                element.on('destroy', scope.$watch(watchablePromise($parse(attributes.whileWorking)), workingIndicatorChanged));

                function workingIndicatorChanged(newWorkingIndicator) {
                    working = newWorkingIndicator;
                    update();
                }

                function update() {
                    if (current) {
                        $animate.removeClass(element, current);
                    }

                    if (working && css) {
                        $animate.addClass(element, css);
                    }

                    current = working && css;
                }
            }
        };
    }

    angular.module('app').directive('whileWorking', whileWorking);
})();