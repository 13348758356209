(function () {
    fileModelDirective.$inject = ['$parse'];
    function fileModelDirective($parse) {
        return {
            restrict: 'A',
            link: function (scope, element, attributes) {
                var model = $parse(attributes.fileModel);
                var modelSetter = model.assign;

                element.bind('change', function () {
                   scope.$apply(function () {
                       modelSetter(scope, element[0].files[0]);
                   }) ;
                });
            }
        };
    }

    angular.module('app').directive('fileModel', fileModelDirective);
})();