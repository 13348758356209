angular.module('app', [
    'ngAnimate',
    'ngMessages',
    'ngSanitize',
    'ngCookies',
    'ngScrollable',
    'ng-showdown',
    'unobtrusive.validation',
    'ui.calendar',
    'ui.bootstrap',
    'ui.bootstrap.tpls',
    'hc.marked',
    'ui.tree',
    'fancyboxplus'
]);

angular.module('app').config(['$interpolateProvider', function($interpolateProvider) {
  $interpolateProvider.startSymbol('{[{');
  $interpolateProvider.endSymbol('}]}');
}]);

angular.module('app').config(['$httpProvider', function($httpProvider) {
    $httpProvider.defaults.xsrfCookieName = 'csrftoken';
    $httpProvider.defaults.xsrfHeaderName = 'X-CSRFToken';
}]);

var ashgrove;