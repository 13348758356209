(function () {
    function watchablePromise() {
        return function (parsedExpression) {
            var lastInput, lastReturned;

            return function (scope) {
                var currentInput = parsedExpression(scope);

                if (currentInput === lastInput) {
                    return lastReturned;
                } else if (currentInput && currentInput.finally) {
                    lastReturned = true;
                    lastInput = currentInput;

                    currentInput.finally(function () {
                        if (currentInput === lastInput) {
                            lastReturned = false;
                        }
                    });

                    return lastReturned;
                } else {
                    lastInput = currentInput;
                    lastReturned = currentInput;

                    return currentInput;
                }
            };
        };
    }

    angular.module('app').factory('watchablePromise', watchablePromise);
})();