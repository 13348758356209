(function () {
    topicViewController.$inject = [];
    function topicViewController() {
        this.checked = [];

        this.isChecked = function (id) {
            return (this.checked.indexOf(id) >= 0);
        };

        this.check = function (id) {
            var index = this.checked.indexOf(id);

            if (index >= 0) {
                this.checked.splice(index, 1);
            } else {
                this.checked.push(id);
            }
        };

        return this;
    }

    angular.module('app').controller('topicViewController', topicViewController);
})();