(function () {
    topicCreateController.$inject = ['attachmentService'];
    function topicCreateController(attachmentService) {
        var instance = this;

        this.data = {'content': ''};
        this.objectName = null;
        this.attachmentData = {};
        this.attachments = [];
        this.isWorking = false;

        this.saveAttachment = function () {
            if (typeof this.attachmentData.file === 'undefined' || !this.attachmentData.file) {
                return;
            }

            this.isWorking = true;

            this.working = attachmentService.saveTemporary(this.objectName, this.attachmentData.file).then(function (result) {
                if (!result.success) {
                    instance.isWorking = false;
                    return;
                }

                instance.attachments.push(result.attachment);
                instance.isWorking = false;
            });
        };

        this.deleteAttachment = function (attachment) {
            this.isWorking = true;

            var data = {
                pk: attachment.pk
            };

            this.working = attachmentService.delete(data).then(function (result) {
                if (!result.success) {
                    instance.isWorking = false;
                    return;
                }

                instance.attachments.splice(instance.attachments.indexOf(attachment), 1);
                instance.isWorking = false;
            });
        };

        this.quote = function () {
            this.data.content += '[quote]' + angular.element('#post-content').val() + '[/quote]';
        };

        this.init = function (objectName) {
            this.isWorking = true;
            this.objectName = objectName;

            var data = {
                objectName: this.objectName
            };

            this.working = attachmentService.getTemporary(data).then(function (result) {
                if (!result.success) {
                    instance.isWorking = false;
                    return;
                }

                instance.attachments = result.attachments;
                instance.isWorking = false;
            });
        };

        return this;
    }

    angular.module('app').controller('topicCreateController', topicCreateController);
})();