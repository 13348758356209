(function () {
    faqIndexController.$inject = [];
    function faqIndexController() {
        this.key = 0;

        this.isOpen = function (key) {
            return this.key === key;
        };

        this.toggle = function (key) {
            if (this.key === key) {
                this.key = 0;
            } else {
                this.key = key;
            }
        };

        return this;
    }

    angular.module('app').controller('faqIndexController', faqIndexController);
})();