(function () {
    attachmentService.$inject = ['$http'];
    function attachmentService($http) {
        this.save = function (objectName, objectPk, fileData) {
            var data = new FormData();

            data.append('object_name', objectName);
            data.append('object_pk', objectPk);
            data.append('file', fileData);

            return $http.post('/api/attachment/create/', data, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            }).then(function (response) {
                if (!response.data) {
                    return {
                        success: false
                    };
                }

                return {
                    success: true,
                    attachment: response.data
                };
            });
        };

        this.saveTemporary = function (objectName, fileData) {
            var data = new FormData();

            data.append('object_name', objectName);
            data.append('file', fileData);

            return $http.post('/api/attachment/create-temporary/', data, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            }).then(function (response) {
                if (!response.data) {
                    return {
                        success: false
                    };
                }

                return {
                    success: true,
                    attachment: response.data
                };
            });
        };

        this.delete = function (data) {
            return $http.post('/api/attachment/delete/', data).then(function (response) {
                if (!response.data) {
                    return {
                        success: false
                    };
                }

                return {
                    success: true
                };
            });
        };

        this.getAll = function (data) {
            var params = {
                object_name: data.objectName,
                object_pk: data.objectPk
            };

            return $http.get('/api/attachments/', { params: params }).then(function (response) {
                if (response.status !== 200) {
                    return {
                        success: false
                    };
                }

                return {
                    success: true,
                    attachments: response.data.attachments,
                    total: response.data.total
                };
            });
        };

        this.getTemporary = function (data) {
            var params = {
                object_name: data.objectName
            };

            return $http.get('/api/attachments/temporary/', { params: params }).then(function (response) {
                if (response.status !== 200) {
                    return {
                        success: false
                    };
                }

                return {
                    success: true,
                    attachments: response.data.attachments,
                    total: response.data.total
                };
            });
        };

        return this;
    }

    angular.module('app').factory('attachmentService', attachmentService);
})();