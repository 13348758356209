(function () {
    baseController.$inject = [];
    function baseController() {
        this.isMobileMenuOpen = false;
        this.openUnits = [];

        this.toggleMobileMenu = function () {
            this.isMobileMenuOpen = !this.isMobileMenuOpen;
        };

        this.isUnitOpen = function (number) {
            return (this.openUnits.indexOf(number) >= 0);
        };

        this.openUnit = function (number) {
            if (this.openUnits.indexOf(number) >= 0) {
                return;
            }

            this.openUnits.push(number);
        };

        this.closeUnit = function (number) {
            if (this.openUnits.indexOf(number) < 0) {
                return;
            }

            this.openUnits.splice(this.openUnits.indexOf(number), 1);
        };

        return this;
    }

    angular.module('app').controller('baseController', baseController);
})();